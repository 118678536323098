export default function TestingService() {
    return (
      <section className="py-20 text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-lg">
        <div className="grid grid-cols-1 lg:grid-cols-[40%_60%] gap-12 items-center">
          {/* Image Section */}
          <div className="flex justify-center lg:justify-start">
            <img
              src="/services_images/services2.png"
              alt="Mediviz Pharma"
              className="w-full lg:w-auto max-h-[500px] rounded-lg object-cover shadow-md"
            />
          </div>
  
          {/* Text Content */}
          <div>
            <h1 className="text-4xl font-bold text-[#1a0a52] mb-4">Analytical Testing Services</h1>
            <div className="w-24 h-[3px] bg-[#7b5cf0] mb-6"></div>
            <p className="text-lg leading-relaxed text-[#333]">
            Mediviz has  established a state-of- the-art Microbiology testing Laboratory in visakhapatnam (Vizag) .             </p>
            <p className="text-lg leading-relaxed text-[#333] mt-4">
            he Facility is in compliance with requirement of USFDA/EU Regulatory Authorities and GMP and Caters to clients of national and international  repute in consonance with global regulatory standards.            </p>
          </div>
        </div>
      </section>
    );
  }
  