import React from 'react';
import Welcome from './Welcome.js';
import AboutUs from './AboutUs.js';
import AboutCompany from './AboutCompany.js';
import ProductAndServices from './ProductAndServices.js';
import WhatWeDo from './WhatWeDo.js';
import MicrobiologyLabPhoto from './MicrobiologyLabPhoto.js';

export default function AboutDetails() {
  return (
   <>
      <Welcome />
      <AboutUs />
      <AboutCompany />
      <ProductAndServices />
      <WhatWeDo />
      <MicrobiologyLabPhoto/>
      </>
  );
}


