import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function HeroSlider() {
  const clients = [
    '/home_images/mediviz_slider_2.jpg',
    '/home_images/mediviz_slider_1.jpg',
    '/home_images/mediviz_slider_3.jpg',
    '/home_images/mediviz_slider_4.jpg',
  ];

  return (
    <div className="w-full">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: true }}
        style={{ height: '650px' }}  // Fixed height for the slider
        breakpoints={{
          640: {
            slidesPerView: 1,
            height: 300,  // Adjust this for smaller screens
          },
          768: {
            slidesPerView: 1,
            height: 400,  // Adjust this for medium screens
          },
          1024: {
            slidesPerView: 1,
            height: 600,  // Fixed height for larger screens
          },
        }}
      >
        {clients.map((client, index) => (
          <SwiperSlide key={index} className="flex items-center justify-center">
            <img
              src={client}
              alt="Client"
              className="w-full h-full object-cover object-center rounded-lg shadow-md"  // Keep image covering the fixed height
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
