import { Droplet, Microscope, Atom, Settings } from "lucide-react";

export default function AnalyticalServices() {
  return (
    <section className="py-20 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-lg">
      {/* Heading Section */}
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-[#1a0a52] mb-4">
          Major Analytical Services on Offer Are
        </h2>
        <div className="w-24 h-[3px] bg-[#7b5cf0] mx-auto"></div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-[40%_60%] gap-12 items-start">
        {/* Image Section */}
        <div className="flex justify-center lg:justify-start h-full">
          <img
            src="/services_images/services3.png"
            alt="Analytical Services"
            className="w-full lg:w-auto max-h-[500px] rounded-lg object-cover shadow-md"
          />
        </div>

        {/* Cards Section */}
        <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 h-full justify-between">
          {/* Card 1 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex items-start">
            <Droplet className="text-[#F2A534] w-10 h-10 mr-4" />
            <div>
              <h3 className="text-2xl font-bold text-[#2E2F77] mb-2">Water Testing</h3>
              <p className="text-lg text-[#333]">
                MICROBIOLOGY <br />
                BET <br />
                TOC <br />
                CHEMICAL PARAMETERS (USP/EP/IP)
              </p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex items-start">
            <Microscope className="text-[#F2A534] w-10 h-10 mr-4" />
            <div>
              <h3 className="text-2xl font-bold text-[#2E2F77] mb-2">Production Testing and Validation For</h3>
              <p className="text-lg text-[#333]">
              MICROBIOLOGY <br />
              BET <br />
              </p>
            </div>
          </div>

          {/* Card 3 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex items-start">
            <Atom className="text-[#F2A534] w-10 h-10 mr-4" />
            <div>
              <h3 className="text-2xl font-bold text-[#2E2F77] mb-2">Clean Room Viable Count</h3>
              <p className="text-lg text-[#333]">
                Settle Plate <br />
                Air Sampling <br />
                RODAC/SWABS
              </p>
            </div>
          </div>

          {/* Card 4 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex items-start">
            <Settings className="text-[#F2A534] w-10 h-10 mr-4" />
            <div>
              <h3 className="text-2xl font-bold text-[#2E2F77] mb-2">Compressed Air / Nitrogen Testing</h3>
              <p className="text-lg text-[#333]">
                Chemical and Physical Paramter Including<br />
                Purity by GC <br />
                Microbiology
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
