export default function ProductCards() {
  const mediaData = [
    { category: "CULTURE MEDIA PLATES", items: [
        { sno: "1", name: "Burkholderia Cepacia Agar", code: "MZPBCSA" },
        { sno: "2", name: "Cetrimide Agar", code: "MZPCA" },
        { sno: "3", name: "Columbia Agar", code: "MZPCLA" },
      ] 
    },
    { category: "BROTH BOTTLES", items: [
        { sno: "1", name: "Buffered Sodium Chloride Peptone Solution", code: "MZBBSCPS" },
        { sno: "2", name: "Buffered Sodium Chloride Peptone Solution with 1% Polysorbate 80", code: "MZBBSCPSP" },
        { sno: "3", name: "Buffered Sodium Chloride Peptone Solution with Polysorbate 80 and Lecithin", code: "MZBBSCPSPL" },
      ] 
    },
    { category: "MOLTEN / RE-MELT MEDIA", items: [
        { sno: "1", name: "Reasoner’s 2A Agar (MOLTEN)", code: "MZMR2A" },
        { sno: "2", name: "Soyabean Casein Digest Agar (MOLTEN)", code: "MZMSCDA" },
        { sno: "3", name: "Sabouraud Dextrose Agar (MOLTEN)", code: "MZMSDA" },
      ] 
    },
  ];

  return (
    <section className="py-20 bg-white text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
      {/* Heading Section */}
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-[#2E2F77] mb-4">
          Manufacturing of Ready to Use Microbiology Media Plates and Broths
        </h2>
        <div className="w-24 h-[3px] bg-[#7b5cf0] mx-auto mb-8"></div>
        <h3 className="text-2xl font-semibold text-[#2E2F77]">Brand Name: Microviz</h3>
      </div>

      {/* Media Cards */}
      {mediaData.map((category, index) => (
        <div key={index} className="w-full mb-12">
          <h3 className="text-xl font-semibold text-[#F2A534] mb-6">{category.category}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {category.items.map((item, idx) => (
              <div key={idx} className="bg-white p-6 rounded-lg shadow-md border border-[#e0e0e0] w-full">
                <p className="text-md text-[#2E2F77] mb-2"><strong>Name of the Media:</strong> {item.name}</p>
                <p className="text-md text-[#2E2F77]"><strong>Product Code:</strong> {item.code}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
}
