import { DownloadIcon } from 'lucide-react';
import React, { useState } from 'react';

function Download() {
  const [batchNumber, setBatchNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    if (!batchNumber) {
      alert("Please enter a batch number.");
      return;
    }
    
    setLoading(true);
    setError(null);

    try {
      // Simulating an API request
      const response = await fetch('https://api.example.com/download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ batchNumber }),
      });

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      // Simulating file download
      const data = await response.blob();
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `batch_${batchNumber}.pdf`;
      link.click();

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="py-24 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
      {/* Heading Section */}
      <div className="text-center mb-16">
        <h1 className="text-5xl font-extrabold text-[#2E2F77] mb-6">
          Download Your File
        </h1>
      </div>

      {/* Batch Number Input and Download Button */}
      <div className="grid grid-cols-1 lg:grid-cols-[65%_35%] gap-12 items-center">
        {/* Input and Button Section */}
        <div>
          <h1 className="text-3xl font-bold mb-4">
            Enter Batch Number to Download
          </h1>
          <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
          
          {/* Batch Number Input */}
          <input
            type="text"
            value={batchNumber}
            onChange={(e) => setBatchNumber(e.target.value)}
            className="text-lg w-full p-3 border border-[#ddd] rounded-md mb-6"
            placeholder="Enter Batch Number"
          />
          
          {/* Error Message */}
          {error && <p className="text-red-600 mb-4">{error}</p>}
          
          {/* Download Button */}
          <button
            onClick={handleDownload}
            disabled={loading}
            className={`${
              loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#2E2F77]'
            } hover:bg-opacity-90 text-white font-semibold py-3 px-6 rounded-full shadow-md transition duration-200`}
          >
            {loading ? 'Downloading...' : 'Download'}
          </button>
        </div>

        {/* Image Section */}
        <div className="flex justify-center lg:justify-start">
          <DownloadIcon />
        </div>
      </div>
    </section>
  );
}

export default Download;
