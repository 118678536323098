import {
    Cloud,
    Code2,
    Database,
    LineChart,
    Shield,
    Smartphone,
  } from 'lucide-react';
import HeroServices from '../components/services/Hero.js';
import Services from '../components/layout/Services.js';
import OurSevice from '../components/services/OurService.js';
import TestingService from '../components/services/TestingService.js';
import AnalyticalServices from '../components/services/AnalyticalService.js';

  
  const services = [
    {
      icon: Code2,
      title: 'Custom Software Development',
      description:
        'We create tailored software solutions that perfectly align with your business needs. Our experienced team uses the latest technologies and best practices to deliver high-quality, scalable applications.',
      features: [
        'Web Applications',
        'Enterprise Software',
        'API Development',
        'Legacy System Modernization',
      ],
    },
    {
      icon: Cloud,
      title: 'Cloud Solutions',
      description:
        'Transform your business with our comprehensive cloud services. We help you migrate, optimize, and manage your cloud infrastructure for maximum efficiency and scalability.',
      features: [
        'Cloud Migration',
        'Cloud Architecture Design',
        'DevOps Implementation',
        'Cloud Security',
      ],
    },
    {
      icon: Smartphone,
      title: 'Mobile Development',
      description:
        'Create powerful mobile experiences for your users with our expert mobile development services. We build native and cross-platform applications that deliver exceptional performance.',
      features: [
        'iOS Development',
        'Android Development',
        'Cross-platform Solutions',
        'Mobile UI/UX Design',
      ],
    },
    {
      icon: Database,
      title: 'Database Management',
      description:
        'Optimize your data infrastructure with our database management services. We ensure your data is secure, efficient, and readily available when you need it.',
      features: [
        'Database Design',
        'Performance Optimization',
        'Data Migration',
        'Backup & Recovery',
      ],
    },
    {
      icon: Shield,
      title: 'Cybersecurity',
      description:
        'Protect your digital assets with our comprehensive cybersecurity solutions. We help you identify and mitigate security risks while ensuring compliance with industry standards.',
      features: [
        'Security Audits',
        'Penetration Testing',
        'Security Training',
        'Incident Response',
      ],
    },
    {
      icon: LineChart,
      title: 'IT Consulting',
      description:
        'Get expert guidance on your technology initiatives. Our consultants help you make informed decisions and develop strategies that align with your business goals.',
      features: [
        'Technology Strategy',
        'Digital Transformation',
        'Process Optimization',
        'IT Assessment',
      ],
    },
  ];
  
  export default function ServicesPage() {
    return (
      <div>
        <HeroServices />
        <OurSevice />
        <TestingService />
        <AnalyticalServices />
      </div>
    );
  }