export default function AboutCompany() {
  // Configuration array with dummy data
  const introSections = [
    {
      title: "Microbiology Testing Services for Pharmaceutical Products:",
      content: "Mediviz offers microbiology testing services to assess the microbial quality of pharmaceutical products. These tests are crucial to ensure that pharmaceutical products meet the required quality standards and comply with regulatory guidelines.",
      imageSrc: "/about_us_images/section1.jpg",
      imageAlt: "Mediviz Pharma"
    },
    {
      title: "Manufacturing Ready to Use Media Plates / Broths:",
      content: "The company is involved in the production of ready-to-use culture media plates and broths, which are essential for microbial testing and research. These media plates and broths are designed to be convenient and reliable for laboratories conducting microbiological analyses.",
      imageSrc: "/about_us_images/section2.jpg",
      imageAlt: "Mediviz Expertise"
    },
    {
      title: "Supplying Microbiology Consumables:",
      content: "Mediviz supplies various microbiology consumables required for laboratory testing, research, and quality control in the pharmaceutical and chemical industries. This includes a wide range of products necessary for microbial culture, identification, and analysis.",
      imageSrc: "/about_us_images/section3.jpg",
      imageAlt: "Innovative Solutions"
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
      {/* Heading Section */}
      <div className="text-center mb-12 text-[#0d2251]">
        <h2 className="text-4xl font-bold mb-4">About Our Company</h2>
        <p>
          Mediviz Pharma Services Private Limited is a company established by a group of highly competent and experienced personnel in the pharmaceutical industry. The core focus of Mediviz revolves around being a comprehensive solution provider for microbiology needs in the pharmaceutical and chemical industries. The company's main business areas include:
        </p>
      </div>

      {/* Dynamic Intro Sections */}
      {introSections.map((section, index) => (
  index % 2 === 0 ? (
    <div
      key={index}
      className="grid grid-cols-1 lg:grid-cols-[65%_35%] gap-12 items-center mb-12"
    >
      {/* Text Content */}
      <div>
        <h1 className="text-3xl font-bold mb-4">{section.title}</h1>
        <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
        <p className="text-lg leading-relaxed mb-6">{section.content}</p>
      </div>

      {/* Image Section */}
      <div className="flex justify-center lg:justify-start">
        <img
          src={section.imageSrc}
          alt={section.imageAlt}
          className="max-h-[500px] w-full lg:w-auto rounded-lg object-cover"
        />
      </div>
    </div>
  ) : (
    <div
      key={index}
      className="grid grid-cols-1 lg:grid-cols-[35%_65%] gap-12 items-center mb-12"
    >
      {/* Image Section */}
      <div className="flex justify-center lg:justify-start">
        <img
          src={section.imageSrc}
          alt={section.imageAlt}
          className="max-h-[500px] w-full lg:w-auto rounded-lg object-cover"
        />
      </div>

      {/* Text Content */}
      <div>
        <h1 className="text-3xl font-bold mb-4">{section.title}</h1>
        <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
        <p className="text-lg leading-relaxed mb-6">{section.content}</p>
      </div>
    </div>
  )
))}

    </section>
  );
}
