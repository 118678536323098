import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function HeroProducts() {
  return (
    <img 
      src="/products_images/products_heroku.jpg" 
      alt="Mediviz Pharma Microbiology" 
      className="w-full h-[600px] object-cover rounded-md" // Full width, custom height, and cover for aspect ratio
    />
  );
}