import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const products = [
  {
    image: "/home_images/whatwedo1.png",
    title: "Water Testing For",
    description: "Microbiology, BET, TOC, Chemical Parameters (USP/EP/IP)",
  },
  {
    image: "/home_images/whatwedo2.png",
    title: "Products Testing and Validations For",
    description: "Microbiology, BET",
  },
  {
    image: "/home_images/whatwedo3.png",
    title: "Clean Room Viable Count",
    description: "Settle Plate, Air  Sampling RODAV/SWABS",
  },
  {
    image: "/home_images/whatwedo4.png",
    title: "Compressed Air Nitrogen Testing",
    description:
      "Chemical/Physical Parameters Including Purity By GC, Microbiology",
  },
  {},
  {
    image: "/home_images/whatwedo5.png",
    title: "Disinfectant Validations",
    description:
      "Microbial efficacy, stability, compatibility, toxicity, residue",
  },
];

export default function WhatWeDo() {
  return (
    <section className="py-20 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] ">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12 text-[#0d2251]">
          <h2 className="text-4xl font-bold mb-4">What do we do?</h2>
          <p>
            Manufacturing of ready-to-use microbiology media plates and broths.
            Operating DCA approved testing laboratory for the following
            services.
          </p>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-6">
          {/* Products Section - 100% width on mobile, 60% on large screens */}
          <div className="w-full lg:w-3/5">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
              {products.map((product, index) =>
                // Check if the product has an image and render conditionally
                product.image ? (
                  <div
                    key={index}
                    className="bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow relative flex flex-col items-center justify-center text-center"
                  >
                    <div className="relative h-24 w-24 rounded-lg bg-[#2E2F77] transition-all duration-300 ease-in-out hover:bg-[#F2A534] p-2 mb-4 flex items-center justify-center">
                      <img
                        src={product.image}
                        alt={product.title}
                        className="h-full w-full object-cover rounded-md"
                        style={{ zIndex: 10 }} // Ensure image is above any background
                      />
                    </div>

                    <div className="bg-[#e0eaf4] p-4 rounded-md mb-4 w-full">
                      <h3 className="text-lg sm:text-xl font-semibold text-[#F2A534] mb-2">
                        {product.title}
                      </h3>
                      <p className="text-sm sm:text-base h-auto md:h-[65px] text-[#2E2F77]">
                        {product.description}
                      </p>
                    </div>
                  </div>
                ) : (
                  // Empty placeholder for desktop grid
                  <div
                    key={index}
                    className="hidden lg:block col-span-1" // Hide on mobile, show empty grid on desktop
                  ></div>
                )
              )}
            </div>
          </div>

          {/* Right Section - full width on mobile, 40% on large screens */}
          <div className="w-full lg:w-2/5 flex items-center justify-center">
            <div className="w-full bg-gray-200 rounded-lg overflow-hidden">
              <img
                src="/home_images/whatwedosidebanner.png"
                alt="Additional Info"
                className="w-full h-64 md:h-[400px] lg:h-[600px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
