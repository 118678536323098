import React from 'react';

export default function MicrobiologyLabPhoto() {
  const facilities = [
    {
      title: "Microbiology Lab And Ready To Use Media Manufacturing Facility (Unit 1)",
      image: "/home_images/site-1.jpg",
      address: "PLOT NO.39, RK LAYOUT, SHIV SHIVANI NAGAR, BESIDE PARAWADA PHARMACITY FLYOVER, LANKE LAPALAM, VISAKAPATNAM - 531021, INDIA",
      contact: "9704291555",
      email: "RAMBABU@MEDIVIZPHARMA.COM"
    },
    {
      title: "Microbiology Lab And Ready To Use Media Manufacturing Facility (Unit 2)",
      image: "/home_images/site-2.jpeg",
      address: "AP MEDTECH ZONE CAMPUS (AMTZ) SURVEY NO. 480/2, BESIDE PRAGATHI MAIDAN, NADUPURU VILLAGE, PEDAGANTYADA (M), VISAKHAPATNAM - 530031, INDIA",
      contact: "9704291555",
      email: "RAMBABU@MEDIVIZPHARMA.COM"
    },
  ];

  return (
    <section className="py-20">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-[#2E2F77] mb-4">Our Microbiology Laboratories</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {facilities.map((facility, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 ease-in-out"
            >
              <h3 className="text-[#2E2F77] text-xl font-semibold mb-2 text-center">
                {facility.title}
              </h3>
              <img
                src={facility.image}
                alt={facility.title}
                className="h-100 object-cover rounded-md mb-4 min-h-100"
              />
              <div className="mb-4">
                <p className="text-gray-800 font-semibold">Facility Address:</p>
                <p className="text-gray-600 mb-2">{facility.address}</p>
                <p className="text-gray-800 font-semibold">Contact:</p>
                <p className="text-gray-600 mb-2">{facility.contact}</p>
                <p className="text-gray-800 font-semibold">Email:</p>
                <p className="text-gray-600">{facility.email}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
