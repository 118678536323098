import { Code2, Github, Linkedin, Twitter, MapPin, Phone, Mail } from "lucide-react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#0d2251] text-gray-300">
      {/* Container */}
      <div className="w-screen px-8 md:px-16 py-12 flex flex-col items-center space-y-16">
        {/* Upper Section */}
        <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-8 items-start max-w-screen-xl">
          {/* Column 1: Company Logo */}
          <div>
            <Link to="/" className="flex flex-col items-center lg:items-start">
              <img
                src="/home_images/mediviz_logo.png"
                alt="Ajna"
                className="h-auto w-60 mb-4"
              />
            </Link>
          </div>

          {/* Column 2: Quick Links */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <Link
                  to="/"
                  className="text-base hover:text-[#cf772f] transition-colors"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-base hover:text-[#cf772f] transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="text-base hover:text-[#cf772f] transition-colors"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/products"
                  className="text-base hover:text-[#cf772f] transition-colors"
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-base hover:text-[#cf772f] transition-colors"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Column 3: Contact Information */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Contact Us</h3>
            <div className="space-y-3">
              <div className="flex items-start space-x-4">
                <MapPin className="h-24 w-24 text-gray-400" />
                <p className="text-base">
                  PLOT NO.39, RK LAYOUT, SHIV SHIVANI NAGAR, BESIDE PARAWADA
                  PHARMACITY FLYOVER, LANKE LAPALAM, VISAKAPATNAM - 531021, INDIA
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <Phone className="h-6 w-6 text-gray-400" />
                <p className="text-base">+91-9704291555</p>
              </div>
              <div className="flex items-center space-x-4">
                <Mail className="h-6 w-6 text-gray-400" />
                <a
                  href="mailto:RAMBABU@MEDIVIZPHARMA.COM"
                  className="text-base hover:text-[#cf772f] transition-colors"
                >
                  RAMBABU@MEDIVIZPHARMA.COM
                </a>
              </div>
            </div>
          </div>

          {/* Column 4: Credentials */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Credentials</h3>
            <ul className="space-y-3">
              <li className="text-base">
                CDSCO(india) & DCA (A.P) Approved Analytical Testing Laboratory
              </li>
              <li className="text-base">
                ISO 13485 Certified facility for Ready to use Media plates manufacturing.
              </li>
              <li className="text-base">
                Corporate Identity Number : U74900AP2013PTC091376
              </li>
              <li className="text-base">GST IN: 37AAICM9599H1ZL</li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="w-full border-t border-gray-800 pt-6 max-w-screen-xl flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-sm text-gray-400">
            © {currentYear} Copyright @ 2024 Mediviz Pharma. All Rights Reserved.
          </p>
          <a
            href="https://ajna.cloud/"
            className="text-sm text-gray-400 hover:text-[#cf772f] transition-colors"
          >
            Designed by Ajna.cloud
          </a>
        </div>
      </div>
    </footer>
  );
}
