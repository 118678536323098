import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const products = [
  {
    image: '/home_images/scientist-fills-petri-dish-with-layer-nutrient-medium-cultivates-colonies-microorganisms-bacteriological-laboratory-bacterial-analysis_74906-3060-1.png', 
    description: 'Operating Microbiology Testing Lab (DCA Approved / ISO Certified) \n \n New microbiology testing laboratory will be inaugurated soon exclusively for drug substances/drug products'
  },
  {
    image: '/home_images/New_Project__17_-removebg-preview-1-qh3kdm6qmw3q9aj69yhjl69gulntbruckj4xi4wa10.png', 
    description: 'Manufacturing of Ready To Use Microbiology Media Plates/ Broths'
  },
  {
    image: '/home_images/doctor-working-medical-research-1.png', 
    description: 'Supplying of consumables uses in Microbiology Laboratory.'
  },
];

export default function ProductAndServices() {
  return (
    <section className="py-20">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-[#0d2251] mb-4">Products</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg transition-transform duration-300 ease-in-out border"
            >
              <img
                src={product.image}
                alt={product.title}
                className="h-90 w-full object-contain rounded-md mb-4"
              />
              <div className="bg-[#e0eaf4] p-4 rounded-md mb-4">
                <h4 className="font-semibold text-[#2E2F77] mb-2">
                  {product.description.split('\n').map((line, i) => (
                    <span key={i}>
                      {line}
                      <br /> {/* Adds a line break after each line */}
                    </span>
                  ))}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
