import HeroAbout from "../components/about/Hero.js";
import WhyUs from "../components/about/WhyUs.js";
import { Watch, CircleDollarSign, Trophy, Handshake } from 'lucide-react'; // Import the icons
import Team from '../components/about/Team.js'
import AboutCompany from '../components/about/AboutCompany.js'
import Founder from "../components/about/Founder.js";
import EstablishedDetails from "../components/about/EstablishedDetails.js";


export default function About() {
  const features = [
    {
      icon: 'Watch',
      title: "Fast time-to-value",
      description:
        "Offering an iterative approach for long-term transformational initiatives, we manage to start providing tangible business value as early as possible. Even first small steps towards a more consistent and balanced IT environment optimize operational expenses, like high maintenance costs for obsolete IT assets.",
    },
    {
      icon: "CircleDollarSign",
      title: "Fair Pricing",
      description:
        "We apply a pricing model depending on the nature of the consulting service we render. Fixed price – for time-bound services with clearly defined deliverables: a feasibility study, solution prototyping, market research. Time & Material – for long-term involvements when the service scope can’t be estimated accurately at the start: an operational review of an IT department, an IT audit, modernization of a bunch of business apps.",
    },
    {
      icon: "Trophy",
      title: "Value Driven IT Services",
      description:
        "The landscape of IT technologies and innovations is vast and growing. We help companies navigate it and make smart IT investments into areas with real profit potential. When planning an IT strategy, we communicate explicitly the value of changes, typically centered around cost reduction, workflow automation, expansion into new revenue channels or service quality improvement. Our value-oriented approach earned us the presence in the prestigious Global Outsourcing 100 listing.",
    },
    {
      icon: "Handshake",
      title: "Assisted Transition for better IT",
      description:
        " We provide a full-service package of assistance for IT projects – we plan, design, implement and continuously support changes envisaged in the IT strategy. You don’t have to bounce between several teams down the road of your transition to an optimized IT infrastructure or digitally transformed processes.",
    },
  ];

  // Mapping of icon names to Lucide components
  const iconMap = {
    Watch,
    CircleDollarSign,
    Trophy,
    Handshake
  };

  return (
    <div>
      <HeroAbout />
      <AboutCompany />
      <Team />
      <Founder />
      <EstablishedDetails />
    </div>
  );
}
