export default function OurSevice() {
    // Configuration array with dummy data
    const introSections = [
      {
        title: "Services",
        content: "Mediviz's microbiological testing laboratory comprises a team of highly experienced microbiologists having hands-on experience in routine as well as special microbiology analytical techniques research microbiology and a gamut of other microbiology testing Further, the microbiology testing laboratory is built in accordance with current regulatory requirements with respect to design, classification, practices and procedures etc.,Mediviz provides cGMP compliant pharmaceutical microbiology testing services for Water ,Clean room, APIs, Packing Materials, Formulations and its raw materials etc.,At Mediviz, we understand the challenges presented by a changing market recruitments and our goal is to maintain cost effective and quality and micro biological testing services of the highest order.Summing up Mediviz is the undisputed leader in microbiology testing service",
        imageSrc: "/services_images/services1.png",
        imageAlt: "Mediviz Pharma"
      },
    ];
  
    return (
      <section className="py-20 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
        {/* Dynamic Intro Sections */}
        {introSections.map((section, index) => (
    index % 2 === 0 ? (
      <div
        key={index}
        className="grid grid-cols-1 lg:grid-cols-[65%_35%] gap-12 items-center mb-12"
      >
        {/* Text Content */}
        <div>
          <h1 className="text-3xl font-bold mb-4">{section.title}</h1>
          <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
          <p className="text-lg leading-relaxed mb-6">{section.content}</p>
        </div>
  
        {/* Image Section */}
        <div className="flex justify-center lg:justify-start">
          <img
            src={section.imageSrc}
            alt={section.imageAlt}
            className="max-h-[500px] w-full lg:w-auto rounded-lg object-cover"
          />
        </div>
      </div>
    ) : (
      <div
        key={index}
        className="grid grid-cols-1 lg:grid-cols-[35%_65%] gap-12 items-center mb-12"
      >
        {/* Image Section */}
        <div className="flex justify-center lg:justify-start">
          <img
            src={section.imageSrc}
            alt={section.imageAlt}
            className="max-h-[500px] w-full lg:w-auto rounded-lg object-cover"
          />
        </div>
  
        {/* Text Content */}
        <div>
          <h1 className="text-3xl font-bold mb-4">{section.title}</h1>
          <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
          <p className="text-lg leading-relaxed mb-6">{section.content}</p>
        </div>
      </div>
    )
  ))}
  
      </section>
    );
  }
  