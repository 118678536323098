import { Code2, Menu, X } from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const menuItems = [
  { path: '/', label: 'Home' },
  { path: '/about', label: 'About Us' },
  { path: '/services', label: 'Services' },
  { path: '/products', label: 'Products' },
  { path: '/download', label: 'Download' },
  { path: '/contact', label: 'Contact' },
];

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className="fixed w-full bg-white shadow-md z-50">
      <div className="flex items-center justify-between px-4">
        {/* Logo shifted to the right with left margin */}
        <Link to="/" className="flex items-center space-x-2 ml-12">
          <img src="/home_images/mediviz_logo.png" alt="Mediviz" className="w-60" />
        </Link>

        {/* Desktop Menu and Mobile Menu Button aligned to the right */}
        <div className="flex items-center pr-12">
          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-4 mr-12">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`text-lg font-medium transition-colors hover:text-[#cf772f] hover:bg-[#152e5d] ${
                  location.pathname === item.path ? 'text-[#cf772f]' : 'text-[#2e2f77]'
                } py-2 px-4 rounded`}
              >
                {item.label}
              </Link>
            ))}
            {/* Get A Quote Button */}
            <Link to="/quote">
              <button
                className="text-lg font-medium transition-colors bg-[#2e2f77] text-white hover:bg-[#cf772f] py-2 px-4 rounded-full"
                onClick={() => setIsMenuOpen(false)}
              >
                Get A Quote
              </button>
            </Link>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-[#2E2F77]" />
            ) : (
              <Menu className="h-6 w-6 text-[#2E2F77]" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="md:hidden py-4">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`block text-lg font-medium transition-colors hover:text-[#cf772f] hover:bg-[#152e5d] ${
                location.pathname === item.path ? 'text-[#cf772f]' : 'text-[#2E2F77]'
              } py-2 px-4 rounded`}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
          {/* Get A Quote Button for Mobile Menu */}
          <Link to="/quote">
            <button
              className="text-lg font-medium transition-colors bg-[#2e2f77] text-white hover:bg-[#cf772f] py-2 px-4 rounded-full"
              onClick={() => setIsMenuOpen(false)}
            >
              Get A Quote
            </button>
          </Link>
        </nav>
      )}
    </header>
  );
}
