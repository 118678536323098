export default function Founder() {
    return (
      <section className="py-20 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
        {/* Heading Section */}
        <div className="text-center mb-12 text-[#0d2251]">
          <h2 className="text-4xl font-bold mb-4">
            Founder And Managing Director
          </h2>
        </div>
  
        <div className="grid grid-cols-1 lg:grid-cols-[40%_60%] gap-12 items-center mb-12">
          {/* Image Section */}
          <div className="flex justify-center">
            <div className="w-48 h-48 lg:w-72 lg:h-72 rounded-full overflow-hidden shadow-lg">
              <img
                src="/about_us_images/Rambabu-photo.jpg"
                alt="M.Rambabu"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
  
          {/* Text Content */}
          <div>
            <h1 className="text-3xl font-semibold mb-4 text-[#0d2251]">Mr. M. Rambabu</h1>
            <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
            <p className="text-lg leading-relaxed text-[#333]">
              Mr. Rambabu has an impressive career spanning two decades in the pharmaceutical sector, with a focus on Quality Control and Quality Assurance domains. His professional journey includes the following key highlights:
            </p>
            <ul className="list-disc list-inside mt-4 text-lg">
              <li><strong>Extensive Experience:</strong> With more than twenty years of experience in the pharma industry, Mr. Rambabu has acquired a deep understanding of various aspects of pharmaceutical operations. He has worked with esteemed organizations such as Dr. Reddy’s Laboratories, Granules India Ltd, and PharmaZell India Ltd, a German Company.</li>
              <li><strong>Quality Management Leadership:</strong> He has held significant roles as Head of Quality Management in multinational companies, showcasing his expertise in ensuring high-quality standards throughout the organization.</li>
              <li><strong>Industry Adaptability:</strong> Given his vast experience, Mr. Rambabu has encountered a diverse range of situations and industry trends, equipping him with the ability to adapt to the evolving landscape of pharmaceutical microbiology and make informed decisions to meet the current requirements and best practices in the field.</li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
  