import AboutDetails from '../components/home/AboutDetails.js';
import Clients from '../components/home/Clients.js';
import HeroSlider from '../components/home/HeroSlider.js';
import Services from '../components/layout/Services.js';

export default function Home() {
  return (
    <div>
      <HeroSlider />
      <AboutDetails />
    </div>
  );
}