export default function Welcome() {
  return (
    <section className="py-20 bg-gradient-to-r from-[#e0eaf4] to-[#f3f8fd] text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
      {/* Heading Section */}
      <div className="text-center mb-16">
        <h1 className="text-5xl font-extrabold text-[#2E2F77] mb-6">
          Welcome to Mediviz Pharma Service Pvt Ltd
        </h1>
      </div>

      {/* Intro Section */}
      <div className="grid grid-cols-1 lg:grid-cols-[65%_35%] gap-12 items-center">
        {/* Text Content */}
        <div>
          <h1 className="text-3xl font-bold mb-4">
            MEDIVIZ PHARMA SERVICES PRIVATE LIMITED
          </h1>
          <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
          <p className="text-lg leading-relaxed mb-6">
            Established in November 2013, we offer a spectrum of integrated Microbiology Analytical Services in Pharma and allied sectors, serving clients in Pharma, Biotech, and Environmental Sciences. Operating seamlessly across national and international arenas.
          </p>
          <p className="text-lg leading-relaxed">
            <span className="font-semibold">Mediviz Pharma Services Pvt Ltd</span> extends its business scope from a Pharma Microbiology Testing Lab in Visakhapatnam to the manufacturing of Ready-to-Use Microbiology Media Plates/Broths, catering to the needs of Pharma and other Healthcare Industries.
          </p>
          <div className="flex space-x-6 mt-8">
            <button className="bg-[#2E2F77] hover:bg-opacity-90 text-white font-semibold py-3 px-6 rounded-full shadow-md transition duration-200">
              Learn More
            </button>
            <button className="bg-[#F2A534] hover:bg-opacity-90 text-white font-semibold py-3 px-6 rounded-full shadow-md transition duration-200">
              Contact Us
            </button>
          </div>
        </div>

        {/* Image Section */}
        <div className="flex justify-center lg:justify-start">
          <img
            src="/home_images/about_mediviz.jpg"
            alt="Mediviz Pharma"
            className="max-h-[500px] w-full lg:w-auto rounded-lg object-cover"
          />
        </div>
      </div>
    </section>
  );
}
