import { CheckCircle } from 'lucide-react';

export default function EstablishedDetails() {
  return (
    <section className="py-20 bg-white text-[#2E2F77] px-8 lg:px-20 rounded-lg shadow-md">
      {/* Heading Section */}
      <div className="text-center mb-12 text-[#0d2251]">
        <h2 className="text-4xl font-bold mb-4">
          Company Establishment: Year 2013
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-[60%_40%] gap-12 items-center mb-12">
        {/* Left-hand Section - Core Business Area */}
        <div>
          <h2 className="text-2xl font-semibold text-[#0d2251] mb-4">Core Business Area</h2>
          <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
          <div className="text-lg space-y-6 text-[#333]">
            <div className="flex items-center">
              <CheckCircle className="text-[#F2A534] mr-3" size={24} />
              <p>Operating Microbiology testing laboratories (DCA/CDSCO approved & ISO 9001:2015 certified)</p>
            </div>
            <div className="flex items-center">
              <CheckCircle className="text-[#F2A534] mr-3" size={24} />
              <p>Manufacturing Ready to Use Culture Media (Agars / Broths) – ISO 13485:2016 certified.</p>
            </div>
            <div className="flex items-center">
              <CheckCircle className="text-[#F2A534] mr-3" size={24} />
              <p>Manufacturing / Supplying of consumables of Microbiology domain</p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <div className="w-full lg:w-[85%] h-[250px] rounded-lg overflow-hidden shadow-lg bg-gray-100">
            <img
              src="/about_us_images/established_image.jpg"
              alt="Microbiology"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-[40%_60%] gap-12 items-center mb-12">
        {/* Image Section */}
        <div className="flex justify-center">
          <div className="w-full lg:w-[85%] h-[250px] rounded-lg overflow-hidden shadow-lg bg-gray-100">
            <img
              src="/about_us_images/established_image.jpg"
              alt="Microbiology"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        {/* Left-hand Section - Expansion Plans */}
        <div>
          <h2 className="text-2xl font-semibold text-[#0d2251] mb-4">
            Expansion Plans: New State of Art Facility is establishing and it will be inaugurated by Q4 of 2023/24 to cater following operations:
          </h2>
          <div className="w-24 h-[3px] bg-[#F2A534] mb-6"></div>
          <div className="text-lg space-y-6 text-[#333]">
            <div className="flex items-center">
              <CheckCircle className="text-[#F2A534] mr-3" size={24} />
              <p>To perform Microbiology testing, Method development & Validations</p>
            </div>
            <div className="flex items-center">
              <CheckCircle className="text-[#F2A534] mr-3" size={24} />
              <p>To manufacture Ready to Use Media (Agars / Broths)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
