import { useEffect, useState } from 'react';
import { Users, Trophy, Briefcase } from 'lucide-react';

export default function AboutCompany() {
  // Counter state
  const [counters, setCounters] = useState({ customers: 0, employees: 0, experience: 0 });

  // Counter animation logic
  useEffect(() => {
    const durations = { customers: 50, employees: 60, experience: 25 }; // Max values
    const interval = 20; // Interval in milliseconds
    const increments = {
      customers: durations.customers / (2000 / interval),
      employees: durations.employees / (2000 / interval),
      experience: durations.experience / (2000 / interval),
    };

    const intervals = {
      customers: setInterval(() => {
        setCounters((prev) => ({
          ...prev,
          customers: Math.min(prev.customers + increments.customers, durations.customers),
        }));
      }, interval),
      employees: setInterval(() => {
        setCounters((prev) => ({
          ...prev,
          employees: Math.min(prev.employees + increments.employees, durations.employees),
        }));
      }, interval),
      experience: setInterval(() => {
        setCounters((prev) => ({
          ...prev,
          experience: Math.min(prev.experience + increments.experience, durations.experience),
        }));
      }, interval),
    };

    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, []);

  return (
    <div className="bg-[#e0eaf4] px-4 sm:px-6 lg:px-8 py-8">
      {/* About Our Company */}
      <div className="text-center mb-12">
        <h2 className="text-2xl font-extrabold text-[#0d2251] tracking-tight md:text-4xl">
          About Our Company
        </h2>
        <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
          We are committed to delivering exceptional value through innovation, expertise, and dedication.
        </p>
      </div>

      {/* Company Stats Section */}
      <div className="flex flex-col sm:flex-row w-full justify-between gap-8 md:gap-12 text-center max-w-full mx-auto">
        {/* Customer Stat */}
        <div className="w-full sm:w-1/3 flex flex-col items-center p-8 rounded-xl bg-white shadow-lg text-[#0d2251] transform transition-transform duration-300 hover:scale-105">
          <Users className="w-20 h-20 mb-6 text-[#F2A534]" />
          <h2 className="text-3xl font-extrabold md:text-4xl">{Math.round(counters.customers)}+</h2>
          <p className="mt-4 text-lg font-medium">Customers</p>
        </div>

        {/* Employees Stat */}
        <div className="w-full sm:w-1/3 flex flex-col items-center p-8 rounded-xl bg-white shadow-lg text-[#0d2251] transform transition-transform duration-300 hover:scale-105">
          <Briefcase className="w-20 h-20 mb-6 text-[#F2A534]" />
          <h2 className="text-3xl font-extrabold md:text-4xl">{Math.round(counters.employees)}+</h2>
          <p className="mt-4 text-lg font-medium">Employees</p>
        </div>

        {/* Years of Industrial Experience Stat */}
        <div className="w-full sm:w-1/3 flex flex-col items-center p-8 rounded-xl bg-white shadow-lg text-[#0d2251] transform transition-transform duration-300 hover:scale-105">
          <Trophy className="w-20 h-20 mb-6 text-[#F2A534]" />
          <h2 className="text-3xl font-extrabold md:text-4xl">{Math.round(counters.experience)}+</h2>
          <p className="mt-4 text-lg font-medium">Years of Industrial Experience</p>
        </div>
      </div>
    </div>
  );
}
