import { BookOpenText, Rocket, Eye } from 'lucide-react';

export default function AboutUs() {
  return (
    <div className="flex flex-col lg:flex-row rounded-lg">
      {/* Image Section */}
      <div className="flex-[40%] flex justify-center lg:justify-start p-6">
        <img
          src="/home_images/mediviz_slide5.png"
          alt="Mediviz Pharma"
          className="max-h-[600px] w-auto rounded-lg object-cover block shadow-none" // Added shadow-none to remove shadow
        />
      </div>

      {/* Content Section */}
      <div className="flex-[60%] flex flex-col justify-center p-6 space-y-12">
        {/* About Us Section */}
        <div className="flex items-start space-x-6">
          <BookOpenText className="h-14 w-14 text-[#F2A534] flex-shrink-0" />
          <div>
            <h1 className="text-3xl text-[#2E2F77] font-bold mb-4">About Us</h1>
            <p className="text-lg text-gray-800 leading-relaxed">
              MEDIVIZ PHARMA SERVICES PRIVATE LIMITED is the organization which is exclusively in 
              Pharmaceutical Microbiology domain. Mediviz is into both Microbiology testing services 
              as well as Microbiology products manufacturing.
            </p>
          </div>
        </div>

        {/* Our Mission Section */}
        <div className="flex items-start space-x-6">
          <Rocket className="h-14 w-14 text-[#F2A534] flex-shrink-0" />
          <div>
            <h1 className="text-3xl text-[#2E2F77] font-bold mb-4">Our Mission</h1>
            <p className="text-lg text-gray-800 leading-relaxed">
              Setting standards to become a benchmark in Microbiology, meeting customer needs and 
              regulatory requirements.
            </p>
          </div>
        </div>

        {/* Vision Section */}
        <div className="flex items-start space-x-6">
          <Eye className="h-14 w-14 text-[#F2A534] flex-shrink-0" />
          <div>
            <h1 className="text-3xl text-[#2E2F77] font-bold mb-4">Vision</h1>
            <p className="text-lg text-gray-800 leading-relaxed">
              To become the ultimate destination for Microbiology needs.
            </p>
            <div className="mt-6">
              <button className="bg-[#2E2F77] hover:bg-opacity-90 transition duration-200 rounded-full text-white font-semibold py-3 px-8 text-lg">
                More About Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
