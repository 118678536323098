import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Team() {
  const teams = [
    {
      image: "/about_us_images/team1-1.png",
    },
    {
      image: "/about_us_images/team2-1-1.png",
    },
    {
      image: "/about_us_images/team3-1.png",
    },
  ];

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-[#0d2251] mb-4">Our Team</h2>
          <p className="text-[#0d2251] text-center">
            Mediviz is proud of its core team, comprising knowledgeable and
            experienced members who play pivotal roles in providing various
            services to the pharmaceutical and chemical industries. With its
            comprehensive offerings and commitment to quality and regulatory
            compliance, Mediviz aims to be the single-stop solution for all
            microbiology needs in the pharmaceutical sector.
          </p>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{ delay: 2000, disableOnInteraction: true }}
          style={{ maxHeight: "600px" }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              height: 300,
            },
            768: {
              slidesPerView: 1,
              height: 400,
            },
            1024: {
              slidesPerView: 1,
              height: 600,
            },
          }}
        >
          {teams.map((team, index) => (
            <SwiperSlide
              key={index}
              className="flex items-center justify-center"
            >
              <img src={team.image} alt="Client" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
