import React, { useState } from 'react';

export default function ContactUsForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        message: '',
    });

    const [snackbar, setSnackbar] = useState({ message: '', type: '', visible: false });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data to send
        const requestBody = {
            sender_email: 'info@ajna.cloud',
            recipient_email: 'sachinjoshi199007@gmail.com', // Replace with the actual recipient email
            subject: 'New Contact Us Enquiry',
            body: `
              You have a new enquiry from the Contact Us form:

              Name: ${formData.name}
              Contact Number: ${formData.contact}
              Email: ${formData.email}
              Message: 
              ${formData.message}

              Please respond promptly.
          `, // Email body with structured formatting
        };

        try {
            const response = await fetch('https://knp769ux95.execute-api.ap-south-1.amazonaws.com/default/Send_Email_Enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const result = await response.json();

            if (response.ok) {
                showSnackbar('Your enquiry has been sent successfully!', 'success');
            } else {
                showSnackbar(result.message || 'There was an error sending your enquiry.', 'failure');
            }
        } catch (error) {
            showSnackbar('Failed to send the enquiry. Please try again.', 'failure');
        }
    };

    const showSnackbar = (message, type) => {
        setSnackbar({ message, type, visible: true });
        setTimeout(() => {
            setSnackbar((prev) => ({ ...prev, visible: false }));
        }, 3000); // Auto-close after 3 seconds
    };

    return (
        <div className="bg-[#e0eaf4] text-[#0d2251] py-8 px-4 md:px-12 w-full flex flex-col md:flex-row justify-center items-center md:items-start md:items-center">
            {/* Image Section */}
            <div className="w-full md:w-1/3 flex justify-center mb-6 md:mb-0">
                <img
                    src="/contact_us_images/contact_us_form.png"
                    alt="Contact Us Enquiry"
                    className="w-full max-w-xs md:max-w-full rounded-md"
                />
            </div>

            {/* Form Section */}
            <div className="w-full md:w-2/3 max-w-2xl mx-auto border-2 flex flex-col justify-center">
                <h2 className="text-3xl font-bold mb-6 text-center md:text-center">Contact Us</h2>

                {/* Form */}
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Name Input */}
                    <div className="flex items-center">
                        <label htmlFor="name" className="w-1/3 text-xl font-semibold">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                            placeholder="Enter your name"
                            required
                        />
                    </div>

                    {/* Email Input */}
                    <div className="flex items-center">
                        <label htmlFor="email" className="w-1/3 text-xl font-semibold">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    {/* Contact Number Input */}
                    <div className="flex items-center">
                        <label htmlFor="contact" className="w-1/3 text-xl font-semibold">Contact Number</label>
                        <input
                            type="tel"
                            id="contact"
                            value={formData.contact}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                            placeholder="Enter your contact number"
                            required
                        />
                    </div>

                    {/* Message Input */}
                    <div className="flex items-start">
                        <label htmlFor="message" className="w-1/3 text-xl font-semibold pt-2">Message</label>
                        <textarea
                            id="message"
                            rows="4"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                            placeholder="What are you looking for?"
                            required
                        ></textarea>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-[#0d2251] text-white font-semibold py-2 rounded-md hover:bg-[#0b1d3d] transition-colors mt-4"
                    >
                        Submit
                    </button>
                </form>
            </div>

            {/* Snackbar */}
            {snackbar.visible && (
                <div
                    className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 p-4 text-white text-center rounded-md ${
                        snackbar.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                    }`}
                >
                    {snackbar.message}
                </div>
            )}
        </div>
    );
}
